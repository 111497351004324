import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
// import Layout from '../components/Layout'

export default class IndexPage extends React.Component {
  render() {
    var styles = {
      marginTop: '-8px',
      fontFamily: 'Roboto, Arial, Helvetica',
      color: 'Teal',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%)',
    };

    return (
      <div>
        <h1 style={styles}>Rebuilding, come back soon!</h1>
      </div>
    )
  }
}

// IndexPage.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(
//       sort: { order: DESC, fields: [frontmatter___date] },
//       filter: { frontmatter: { templateKey: { eq: "one-page" } }}
//     ) {
//       edges {
//         node {
//           excerpt(pruneLength: 400)
//           id
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             templateKey
//             date(formatString: "MMMM DD, YYYY")
//           }
//         }
//       }
//     }
//   }
// `